@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url(//fonts.googleapis.com/css?family=Lato:300:400); */

body {
  margin: 0;
}

.feature_header {
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
}

.banner-bg {
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
}

/* TEAM PAGE  */
.team-header {
  position: relative;
  text-align: center;
  background: linear-gradient(
      60deg,
      rgba(84, 58, 183, 0.6) 0%,
      rgba(0, 172, 193, 1) 100%
    ),
    url("./media/shapelined.jpg");
  background-attachment: fixed;
  background-repeat: none;
  background-position: center;
  background-size: cover;
  color: white;
  max-height: 200px;
}
.team-inner-header {
  height: 22vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.team-bg {
  background-color: #f4f4f5;
  /* background-color: #ffffff; */
  /* background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23b9bdca' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
}

#team-banner-bg {
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
  /* background-color: #2960f7; */
  /* background-image: url("./media/textures/brick-wall-dark.png"); */
}
.text-character {
  background-image: url("./media/TextCharacter.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.wave_title {
  font-family: "Lato", sans-serif !important;
  font-weight: 900;
  letter-spacing: 5px;
  font-size: 80px;
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(
      60deg,
      rgba(84, 58, 183, 0.6) 0%,
      rgba(0, 172, 193, 1) 100%
    ),
    url("./media/test.png");
  background-attachment: fixed;
  background-repeat: none;
  background-position: center;
  background-size: cover;
  color: white;
}

.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 60vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.header-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 200px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Hero Animation */
/* 
.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 17s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 20s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 23s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 30s;
} */

/* FEATURES SECTION */

/* #animate-1 {
  animation: slideInLeft;
  animation-duration: 2.5s;
}

#animate-2 {
  animation: slideInRight;
  animation-duration: 3s;
} */

/* FEATURE CONTAINER SECTION  */

/* #fc-anim {
  animation: zoomIn;
  animation-duration: 3s;
}

#fc-anim2 {
  animation: fadeIn, slideInLeft;
  animation-duration: 3s;
}

#form_anim {
  animation: fadeIn;
  animation-duration: 2s;
} */

/* @keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
} */

/*Shrinking hero for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  .wave_title {
    font-size: 24px;
  }
}

/* contact page */
.contact-bg {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23b9bdca' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
#form_cover {
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
}

@media (orientation: landscape) and (min-height: 220px) and (max-height: 480px) {
  .header {
    height: 450px;
    min-height: 450px;
    padding: -50px 0px 20px 0px;
  }
  .inner-header {
    height: 450px;
    min-height: 450px;
  }
  .waves {
    margin: 0px 0px -50px 0px;
  }
}
